import React, { FC } from 'react';
import classnames from 'classnames';

import { ContainerProps } from './models';

const CONTAINER_CLASS_NAME = 'container';

const Container: FC<ContainerProps> = ({
  element,
  className,
  fluid,
  variant,
  gutter = true,
  innerRef,
  children,
  ...rest
}) => {
  const Tag = element || 'div';

  return (
    <Tag
      className={classnames(CONTAINER_CLASS_NAME, className, {
        [`${CONTAINER_CLASS_NAME}--fluid`]: fluid,
        [`${CONTAINER_CLASS_NAME}--${variant}`]: variant,
        [`${CONTAINER_CLASS_NAME}--gutter`]: gutter,
      })}
      ref={innerRef}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Container;
