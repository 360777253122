import React, { FC, useMemo } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import classNames from 'classnames';

import { getArtDirectedImages } from './utils';

import { ImageBreakpoint, ImageDataLike, ImageProps, ResponsiveImage } from './models.d';

import './Image.scss';

const defaultBreakpoints: ImageBreakpoint[] = [
  { alias: 'mobile', media: '(max-width: 767px)' },
  { alias: 'tablet', media: '(max-width: 991px)' },
];

const Image: FC<ImageProps> = ({
  className,
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const imageClasses = classNames('image', className);
  const isArtDirected = Object.keys(imageData).includes('desktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ResponsiveImage).desktop)
    : getImage(imageData as ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedImages(breakpoints, imageData as ResponsiveImage)
  );

  return useMemo(
    () => (
      <div className={imageClasses}>
        <GatsbyImage image={images} alt={alt} {...restProps} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export const createImageWithBreakpoints =
  (breakpoints: ImageBreakpoint[]): FC<ImageProps> =>
  (props: ImageProps) =>
    <Image {...props} breakpoints={breakpoints} />;

export default Image;
