import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import Image from 'common/Image';

import { useCurrentLang } from 'hooks/useCurrentLang';

import { LogoProps } from './models.d';

const Logo: FC<LogoProps> = ({ url, className }) => {
  const logoClasses = classNames('logo', className);
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allBrandSettings: { nodes: brandSettingsNodes },
    allHomepage: { nodes: homepageNodes },
  } = useStaticQuery(graphql`
    {
      allBrandSettings {
        nodes {
          brandName
          brandLogo {
            childImageSharp {
              gatsbyImageData(width: 368, placeholder: BLURRED, quality: 100)
            }
          }
          lang
        }
      }
      allHomepage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const { brandLogo, brandName } = brandSettingsNodes.find(({ lang }) => lang === currentLangCode);
  const to = url || homepageNodes.find(({ lang }) => lang === currentLangCode).url;

  return (
    <Link to={to} aria-label={brandName}>
      <Image imageData={brandLogo} alt={brandName} objectFit="contain" className={logoClasses} />
    </Link>
  );
};

export default Logo;
