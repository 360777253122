import React, { FC } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';
import './Layout.scss';

import Loadable from 'components/Loadable';
import Footer from 'containers/Footer';
import Header from 'containers/Header';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className }) => {
  const CSSclassNames = classNames('layout', 'background--white', className, {
    'layout--transparent': headerTransparent,
  });

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>

      <Header />

      <main id="main" className="layout__main">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
