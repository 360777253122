import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { DropdownProps } from './models.d';

import './Dropdown.scss';

const Dropdown: FC<DropdownProps> = ({ button, content, className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownClasses = classNames('dropdown', className, {
    'dropdown--open': isOpen,
  });

  return (
    <div
      className={dropdownClasses}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      data-testid="dropdown-item"
    >
      <span className="dropdown__button">{button}</span>
      <div className="dropdown__content background--white shadow--dark-green">
        {content.map((element: DropdownProps['button'], index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="dropdown__item">
            {element}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
