export enum LinkVariant {
  MAIN = 'main',
  DROPDOWN = 'dropdown',
}

export interface DesktopNavigationProps {
  navigation: LinkType[];
  className?: string;
  priceSpiderSku?: string;
}
