export const isBrowser = () => typeof window !== 'undefined';

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const getLocationQueryStringParam = (
  param: string,
  strategy: 'string' | 'array' = 'array'
) => {
  if (!isBrowser()) {
    return strategy === 'string' ? '' : [];
  }
  const qs = new URLSearchParams(window.location.search);

  const stringValue = decodeURIComponent(qs.get(param) || '');
  if (strategy === 'string') {
    return stringValue;
  }

  try {
    return JSON.parse(`[${stringValue}]`);
  } catch (e) {
    console.error(e);

    return [];
  }
};

export const generateLocationQueryString = (param: string, value: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${getLocationPath()}?${qs.toString()}`;
};

export const prepareClassName = (name: string) => String(name).replace(/\s/g, '-').toLowerCase();

export const removeJsOrCss = (filename, isJs = true) => {
  const tags = document.getElementsByTagName(isJs ? 'script' : 'link');

  for (let i = tags.length; i >= 0; i -= 1) {
    if (
      tags[i] &&
      tags[i].getAttribute(isJs ? 'src' : 'href') !== null &&
      tags[i].getAttribute(isJs ? 'src' : 'href')!.indexOf(filename) !== -1
    ) {
      tags[i].parentNode!.removeChild(tags[i]);
    }
  }
};

export const removeElementsByClassName = (className) => {
  const elements = document.querySelectorAll(`.${className}`);

  elements.forEach((element) => {
    element.remove();
  });
};

export const removeElementById = (Id) => {
  const elem = document.querySelector(`#${Id}`);
  if (elem) {
    elem.remove();
  }
};
