import React, { useEffect } from 'react';

import Switch from 'common/Switch';
import Link from 'components/Link';

import { removeElementById, removeElementsByClassName, removeJsOrCss } from 'utils/browser';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useLanguageSwitch } from 'hooks/useLanguageSwitch';

import { generateLangName } from './utils';

import './LanguageSwitch.scss';

const LanguageSwitch = (): JSX.Element | null => {
  const { list } = useLanguageSwitch();
  const { currentLang } = useCurrentLang();
  const languagesCount = list.length;

  useEffect(() => {
    const removePriceSpider = () => {
      if (window.PriceSpider) {
        removeJsOrCss('cdn.pricespider');
        removeJsOrCss('cdn.pricespider', false);
        removeElementsByClassName('ps-container');
        removeElementById('ps-lightbox-background');
        window.PriceSpider = null;
      }
    };

    removePriceSpider();

    return () => {
      removePriceSpider();
    };
  }, []);

  if (languagesCount < 2) return null;

  const renderSwitch = (id: number): JSX.Element | null => {
    if (id === 0) return null;

    const isChecked = Boolean(list.findIndex((lang) => lang.lang === currentLang[0]));
    const switchUrl = isChecked ? list[0].url : list[1].url;

    return (
      <Link url={switchUrl}>
        <Switch isChecked={isChecked} />
      </Link>
    );
  };

  const renderLanguageLinks = list.map(
    ({ currentPage, url, langName, lang }, id): JSX.Element | null => {
      if (!lang) return null;

      return (
        <li key={url}>
          {renderSwitch(id)}
          <Link url={url} active={currentPage} weight="bold" size={{ base: 24, lg: 14, xl: 18 }}>
            {langName || generateLangName(lang)}
          </Link>
        </li>
      );
    }
  );

  return <ul className="language-switch">{renderLanguageLinks}</ul>;
};

export default LanguageSwitch;
