import { graphql, useStaticQuery } from 'gatsby';

export const useNavigationQuery = () =>
  useStaticQuery(graphql`
    {
      allNavigation {
        nodes {
          navigation {
            ...NavigationLinkFragment
          }
          priceSpiderSku
          lang
        }
      }
      allFooter {
        nodes {
          lang
          id
          links {
            ...NavigationLinkFragment
          }
          description
          bottomLinks {
            ...NavigationLinkFragment
          }
        }
      }
    }
  `);
