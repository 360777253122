import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import runOnUserInteraction from 'utils/runOnUserInteraction';

import { PriceSpiderProps } from './models.d';

import './PriceSpider.scss';

const PriceSpider: FC<PriceSpiderProps> = ({ lang }) => {
  const [shouldPriceSpiderLoad, setShouldLoadPriceSpider] = useState(false);

  useEffect(() => {
    runOnUserInteraction(() => setShouldLoadPriceSpider(true));
  }, []);

  return (
    <Helmet>
      <meta name="ps-key" content={process.env.GATSBY_PRICE_SPIDER_KEY} />
      <meta name="ps-country" content={process.env.GATSBY_PRICE_SPIDER_COUNTRY} />
      <meta name="ps-language" content={lang.split('-')[0]} />
      {shouldPriceSpiderLoad ? (
        <script
          src={`${process.env.GATSBY_PRICE_SPIDER_SCRIPT_URL}`}
          type="text/javascript"
          async
        />
      ) : null}
    </Helmet>
  );
};

export default PriceSpider;
