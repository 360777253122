import React, { FC } from 'react';
import classNames from 'classnames';

import NavBase from 'common/NavBase';
import Typography from 'common/Typography';

import { LinkProps } from './models.d';

import './Link.scss';

const Link: FC<LinkProps> = ({
  children,
  color = 'dark-green',
  active,
  className,
  onClick,
  url,
  queryString,
  ariaLabel,
  lineHeight,
  weight,
  italic,
  size,
  padding,
}) => {
  const linkClasses = classNames(
    'link',
    {
      [`link--${color}`]: color,
      'link--active': active,
      'link--no-hover': url === '#' || !url,
    },
    className
  );

  const navBaseProps = { onClick, url, queryString, ariaLabel };
  const typographyProps = {
    lineHeight,
    weight,
    italic,
    size,
    padding,
  };

  return (
    <NavBase className={linkClasses} {...navBaseProps}>
      <Typography as="span" {...typographyProps}>
        {children}
      </Typography>
    </NavBase>
  );
};

export default Link;
