import React, { FC } from 'react';

import { Container } from 'layout';
import Logo from 'common/Logo';
import Typography from 'common/Typography';
import Link, { LinkProps } from 'components/Link';

import { useCurrentLang } from 'hooks/useCurrentLang';
import { useNavigationQuery } from 'hooks/useNavigationQuery';

import { LinkVariant } from './models.d';

import './Footer.scss';

const { BOTTOM, TOP_PRIMARY, TOP_SECONDARY } = LinkVariant;
const SEPARATOR = '|';

const Footer: FC = () => {
  const { currentLang } = useCurrentLang();
  const {
    allFooter: { nodes },
  } = useNavigationQuery();
  const [currentLangCode] = currentLang;
  const footerData = nodes.find(({ lang }) => lang === currentLangCode);
  const { links, bottomLinks, description } = footerData;

  const renderLink = (link: CommonTypes.LinkType, type: LinkVariant = TOP_PRIMARY) => {
    const {
      label,
      url: { url, queryString },
    } = link;

    const linkProps = (): LinkProps | null => {
      switch (type) {
        case TOP_PRIMARY:
          return {
            size: { base: 18, md: 20 },
            weight: 'bold',
            padding: { bottom: { base: 'md', md: 'lg' } },
            url,
            queryString,
          };
        case TOP_SECONDARY:
          return {
            size: 16,
            weight: 'bold',
            padding: { bottom: 'sm' },
            url,
            queryString,
          };
        case BOTTOM:
          return {
            url,
            queryString,
            size: 14,
          };
        default:
          return null;
      }
    };

    return (
      <Link key={label} {...linkProps()}>
        {label}
      </Link>
    );
  };

  const renderTopLinks = links.map((link: CommonTypes.LinkType) => (
    <div key={link.label} className="footer__links">
      {renderLink(link, TOP_PRIMARY)}
      {link.navigationWithSubMenu?.map((subLink) => renderLink(subLink, TOP_SECONDARY))}
    </div>
  ));

  const renderBottomLinks = bottomLinks.map((link: CommonTypes.LinkType, index: number) => (
    <li key={link.label}>
      {index > 0 ? <Typography padding={{ right: 'xs' }}>{SEPARATOR}</Typography> : null}
      {renderLink(link, BOTTOM)}
    </li>
  ));

  return (
    <Container
      element="footer"
      fluid
      className="footer background--white"
      data-testid="footer-item"
    >
      <Container gutter={false}>
        <div className="footer__top">
          {renderTopLinks}
          <Logo className="footer__logo" />
        </div>
        <div className="footer__bottom">
          <ul className="footer__links">{renderBottomLinks}</ul>
          <Typography
            className="footer__copyrights"
            size={14}
            dangerouslySetInnerHTML={description}
          />
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
