type DeferFunction = (callback: () => void, params) => void;

const DEFER_TIMEOUT = 1000;

const getDeferFunctionImplementation = (timeout) => {
  const isRequestIdleCallbackAvailable = 'requestIdleCallback' in window;

  const deferFunction: DeferFunction = isRequestIdleCallbackAvailable
    ? (window.requestIdleCallback as DeferFunction)
    : setTimeout;

  const deferParams = isRequestIdleCallbackAvailable ? { timeout } : timeout;

  return {
    deferFunction,
    deferParams,
  };
};

export default function runOnUserInteraction(callback: () => void, timeout = DEFER_TIMEOUT) {
  const USER_INTERACTION_EVENTS = ['mousemove', 'touchmove'];
  const { deferFunction, deferParams } = getDeferFunctionImplementation(timeout);

  const eventHandler = () => {
    deferFunction(callback, deferParams);

    USER_INTERACTION_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  USER_INTERACTION_EVENTS.forEach((eventName) => {
    window.addEventListener(eventName, eventHandler);
  });
}
