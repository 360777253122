import React, { FC, useEffect, useState } from 'react';

import { Container } from 'layout';
import Logo from 'common/Logo';
import Hamburger from 'components/Hamburger';
import PriceSpider from 'components/PriceSpiderWidget';
import DesktopNavigation from 'containers/DesktopNavigation';
import MobileNavigation from 'containers/MobileNavigation';

import { isBrowser } from 'utils/browser';
import { toggleState } from 'utils/toggleState';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useNavigationQuery } from 'hooks/useNavigationQuery';

import './Header.scss';

const Header: FC = () => {
  const { currentLang } = useCurrentLang();
  const {
    allNavigation: { nodes },
  } = useNavigationQuery();
  const [isMobileOpen, setIsMobileOpen] = useState<boolean | null>(null);
  const [currentLangCode] = currentLang;
  const { navigation, priceSpiderSku } = nodes.find(({ lang }) => lang === currentLangCode) || {};

  useEffect(() => {
    if (!isBrowser()) return;
    const bodyElement = window.document.body;

    if (isMobileOpen) {
      bodyElement.classList.add('scroll--prevent');
    } else {
      bodyElement.classList.remove('scroll--prevent');
    }
  }, [isMobileOpen]);

  return (
    <Container
      fluid
      element="header"
      className="header background--white"
      data-testid="header-item"
    >
      <PriceSpider lang={currentLang[0]} />
      <Container gutter={false} className="header__content background--white">
        <Logo className="header__logo" />
        <DesktopNavigation
          className="show--on-lg"
          navigation={navigation}
          priceSpiderSku={priceSpiderSku}
        />
        <Hamburger
          isOpen={isMobileOpen}
          onClick={() => toggleState(setIsMobileOpen)}
          className="hide--on-lg"
        />
      </Container>
      <MobileNavigation
        isOpen={isMobileOpen}
        setIsOpen={setIsMobileOpen}
        className="hide--on-lg background--white"
        navigation={navigation}
        priceSpiderSku={priceSpiderSku}
      />
    </Container>
  );
};

export default Header;
