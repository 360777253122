import React, { FC } from 'react';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import LanguageSwitch from 'components/LanguageSwitch';
import Link, { LinkProps } from 'components/Link';

import { DesktopNavigationProps, LinkVariant } from './models.d';

import './DesktopNavigation.scss';

const { MAIN, DROPDOWN } = LinkVariant;

const DesktopNavigation: FC<DesktopNavigationProps> = ({
  navigation,
  priceSpiderSku,
  className,
}) => {
  const LEFT_SIDE_ITEMS = 3;
  const desktopNavigationClasses = classNames('desktop-navigation', className);
  const renderLink = (link: CommonTypes.LinkType, type: LinkVariant = MAIN) => {
    const {
      label,
      url: { url },
    } = link;

    const linkProps = (): LinkProps | null => {
      switch (type) {
        case MAIN:
          return {
            className: 'desktop-navigation__item',
            size: { base: 14, xl: 18 },
          };
        case DROPDOWN:
          return {
            size: { base: 14, xl: 18 },
          };
        default:
          return null;
      }
    };

    return (
      <Link key={label} url={url} weight="bold" {...linkProps()}>
        {label}
      </Link>
    );
  };

  const renderDropdown = (link: CommonTypes.LinkType) => {
    if (!link.navigationWithSubMenu) return null;
    const { navigationWithSubMenu, label } = link;
    const dropdownContent = navigationWithSubMenu.map((item) => renderLink(item, DROPDOWN));

    return (
      <Dropdown
        key={label}
        button={renderLink(link, DROPDOWN)}
        content={dropdownContent}
        className="desktop-navigation__item"
      />
    );
  };

  const renderContent = navigation.map((item, index) => {
    if (index > LEFT_SIDE_ITEMS - 1) return null;

    return item.navigationWithSubMenu ? renderDropdown(item) : renderLink(item);
  });

  return (
    <nav className={desktopNavigationClasses}>
      <div className="desktop-navigation__left">{renderContent}</div>
      <div className="desktop-navigation__right">
        {priceSpiderSku ? <div className="ps-widget" ps-sku={priceSpiderSku} /> : null}
        <LanguageSwitch />
        {renderLink(navigation[navigation.length - 1])}
      </div>
    </nav>
  );
};

export default DesktopNavigation;
