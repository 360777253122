import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Accordion, { AccordionVariant } from 'components/Accordion';
import LanguageSwitch from 'components/LanguageSwitch';
import Link, { LinkProps } from 'components/Link';

import { LinkVariant, MobileNavigationProps } from './models.d';

import './MobileNavigation.scss';

const { MAIN, ACCORDION_PRIMARY, ACCORDION_SECONDARY } = LinkVariant;

const MobileNavigation: FC<MobileNavigationProps> = ({
  navigation,
  isOpen,
  setIsOpen,
  className,
  priceSpiderSku,
}) => {
  const mobileNavigationClasses = classNames(
    'mobile-navigation',
    { 'mobile-navigation--open': isOpen },
    className
  );

  const renderLink = (link: CommonTypes.LinkType, type: LinkVariant = MAIN) => {
    const {
      label,
      url: { url },
    } = link;

    const linkProps = (): LinkProps | null => {
      switch (type) {
        case MAIN:
          return {
            className: 'mobile-navigation__item',
            size: 24,
            onClick: () => setIsOpen && setIsOpen(false),
          };
        case ACCORDION_PRIMARY:
          return {
            size: 24,
          };
        case ACCORDION_SECONDARY:
          return {
            size: 18,
            padding: { top: 'xs' },
            onClick: () => setIsOpen && setIsOpen(false),
          };
        default:
          return null;
      }
    };

    return (
      <Link url={url} key={label} weight="bold" {...linkProps()}>
        {label}
      </Link>
    );
  };

  const renderAccordion = (link) => {
    const { navigationWithSubMenu, label } = link;
    const dropdownContent = navigationWithSubMenu.map((item) =>
      renderLink(item, ACCORDION_SECONDARY)
    );

    return (
      <Accordion
        key={label}
        visibleContent={renderLink(link, ACCORDION_PRIMARY)}
        hiddenContent={dropdownContent}
        variant={AccordionVariant.SECONDARY}
        className="mobile-navigation__item"
      />
    );
  };

  const renderContent = navigation.map((item: CommonTypes.LinkType) =>
    item.navigationWithSubMenu ? renderAccordion(item) : renderLink(item)
  );

  return (
    <Container element="nav" gutter={false} className={mobileNavigationClasses}>
      <div className="mobile-navigation__top">
        {renderContent}
        {priceSpiderSku ? <div className="ps-widget" ps-sku={priceSpiderSku} /> : null}
      </div>
      <div className="mobile-navigation__bottom">
        <LanguageSwitch />
      </div>
    </Container>
  );
};

export default MobileNavigation;
