export enum LinkVariant {
  MAIN = 'main',
  ACCORDION_PRIMARY = 'accordionPrimary',
  ACCORDION_SECONDARY = 'accordionSecondary',
}
export interface MobileNavigationProps {
  navigation: CommonTypes.LinkType[];
  isOpen?: boolean | null;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  priceSpiderSku?: string;
}
