import React, { FC, useState } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import { toggleState } from 'utils/toggleState';

import { AccordionProps, AccordionVariant } from './models.d';

import './Accordion.scss';

const { PRIMARY, SECONDARY, TERTIARY } = AccordionVariant;

const buttonDefault = {
  openText: 'more',
  closedText: 'less',
};

const Accordion: FC<AccordionProps> = ({
  visibleContent,
  hiddenContent,
  button = buttonDefault,
  variant,
  iconSize,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const accordionClasses = classNames('accordion', className, {
    [`accordion--${variant}`]: variant,
    [`accordion--icon-${iconSize}`]: iconSize,
    'accordion--open': isOpen,
  });

  const accordionButtonClasses = classNames('accordion__button', {
    'total-disabled--child': !isOpen,
  });
  const accordionHiddenClasses = classNames('accordion__hidden-content');
  const renderIcon =
    variant === PRIMARY || variant === SECONDARY ? <div className="accordion__icon" /> : null;

  const renderAccordion = () => {
    const buttonText = isOpen ? button.closedText : button.openText;

    switch (variant) {
      case PRIMARY:
      case SECONDARY:
        return (
          <>
            <Button
              variant="simple"
              space="between"
              className={accordionButtonClasses}
              onClick={() => toggleState(setIsOpen)}
            >
              {visibleContent}
              {renderIcon}
            </Button>
            <div className={accordionHiddenClasses}>{hiddenContent}</div>
          </>
        );
      case TERTIARY:
        return (
          <>
            <div className="accordion__visible-content">{visibleContent}</div>
            <div className={accordionHiddenClasses}>{hiddenContent}</div>
            <Button
              variant="outline"
              size={15}
              padding={{ left: 'xxs', right: 'xxs' }}
              weight="bold"
              className="accordion__button"
              onClick={() => toggleState(setIsOpen)}
            >
              {buttonText}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={accordionClasses} data-testid="accordion-item">
      {renderAccordion()}
    </div>
  );
};

export default Accordion;
