type AccordionButton = {
  openText: string;
  closedText: string;
};

type AccordionIconSize = 'big' | 'small';

export enum AccordionVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface AccordionProps {
  visibleContent: string | React.ReactNode;
  hiddenContent: string | React.ReactNode;
  variant: AccordionVariant;
  iconSize?: AccordionIconSize;
  button?: AccordionButton;
  className?: string;
}
